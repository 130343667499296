.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
    background-color: #fff0bf !important;
    color: #BABDBF !important;
    border-color: #be7b08 !important;
}

.nav-link-gdc-selected {
    font-weight: bold !important;
}

div.font-montserrat {
    font-family: "Montserrat", sans-serif
}

div.font-roboto {
    font-family: "Robot", sans-serif
}

div.font-opensans {
    font-family: "Open Sans", sans-serif;
    font-size: 14px
}

/* div.rtl{ */
div.rtl {
    direction: rtl !important;
    text-align: right;
}

.sticky-top {
    width: calc(100% - 280px);
    position: fixed;
}

.marginTop {
    margin-top: 7.2% !important;
}

/* -----spinner--- */
.common_loader_ag_grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loader_img_style_common_ag_grid {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_common_ag_grid {
    height: 80px;
    width: 80px;
    color: #1c3078 !important;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: rgb(255, 255, 255, 0.5);
    z-index: 2;
}

/* ----------------hotel select---------- */
.all_search_image_size {
    height: 34px !important;
    width: 34px !important;
}

.contain_image {
    object-fit: contain;
}

.all_search_text_size {
    position: relative;
    top: 2px;
    margin-left: 0px;
}

/* ---------------------- */
.job_serch_form .keyword_box {
    padding: 7.5px !important;
}

.career .career_description {
    word-wrap: break-word !important;
}